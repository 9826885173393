import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import { sortReviewsByDate } from "../utils/utils"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/animations/scroll.scss"
import FixedFacewallHomepage from "./index/FixedFacewallHomepage"
import StaticHero from "./index/StaticHero"
import StaticHeading from "./index/StaticHeading"
import Sizzle from "./index/Sizzle"
import ImageText from "./index/ImageText"
import AllRatingsReviews from "../components/SocialReviews/AllSocialReviews"
import FirstTime from "./index/FirstTime"
import InTheCommunity from "./index/InTheCommunity"
import ReferringProvider from "./index/ReferringProvider"
import LearnMoreCards from "./index/LearnMoreCards"
import Exparel from "./index/Exparel"
import ASIRD from "./index/ASIRD"
import AAAHC from "./index/AAAHC"
import ProgramToast from "../components/Toast/ProgramToast"
import TestimonialGrid from "../components/Cards/TestimonialGrid"

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      didScroll: false,
      scrollPosition: "relative",
      scrollOverflow: "unset",
      playing: false,
      extTheater: false,
      toggleCarouselsClass: false,
    }
    this.toggleCarousel = this.toggleCarousel.bind(this)
  }

  toggleCarousel() {
    var that = this
    that.setState({ toggleCarouselsClass: true })
    window.removeEventListener(
      "aos:in:home-section",
      this.toggleCarousel,
      false
    )
    window.removeEventListener(
      "aos:in:featured-carousel",
      this.toggleCarousel,
      false
    )
    window.removeEventListener("scroll", this.toggleCarousel, false)
  }

  componentDidMount() {
    window.addEventListener("aos:in:home-section", this.toggleCarousel)
    window.addEventListener("aos:in:featured-carousel", this.toggleCarousel)
    window.addEventListener("scroll", this.toggleCarousel)
  }

  render() {
    var pageContext = this.props.pageContext
    var post
    var language = this.props.pageContext.language

    if (pageContext && pageContext.language) {
      switch (language) {
        case "en":
          post = this.props.data.allUniquePagesJson.nodes[0]
          break
        case "es":
          post = this.props.data.spanishIndex.nodes[0]

          break
        default:
          post = this.props.data.allUniquePagesJson.nodes[0]
      }
    } else {
      post = this.props.data.allUniquePagesJson.nodes[0]
    }

    var staticStyle = {
      position: this.state.scrollPosition,
      overflow: this.state.scrollOverflow,
      width: "100%",
      zIndex: 1,
    }
    var sizzleButtonStyle = {
      position: "absolute",
      top: 0,
      zIndex: 200,
    }

    const hasReviews = this.props.data.allReviews.nodes.length > 0
    let allReviews = this.props.data.allReviews.nodes
    let sortedReviews = sortReviewsByDate(allReviews)

    let fixedFacewallReviews = []
    if (sortedReviews.length >= 8) {
      fixedFacewallReviews = sortedReviews.slice(0, 8)
    } else {
      fixedFacewallReviews = sortedReviews.slice(0, 4)
    }

    return (
      <Layout
        className={`main-homepage ${language === "es" ? "es" : null}`}
        language={language}
        layoutClass={"homepage-fab"}
        noFab={true}
        pageTitle={"main-homepage"}
        toastText={
          language === "en" ? (
            <>
              <span className="whole-div">
                <Link to="/wisdom/"></Link>
              </span>
              <div className="toast-message-container">
                <a>
                  Need your wisdom teeth removed? Apply for our Wisdom For
                  Wisdom program.{" "}
                  <span className="bold-underline">Learn&nbsp;More</span>
                </a>
              </div>
            </>
          ) : null
        }
      >
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          schemaPage={post.schemaPage}
          pathname={this.props.location.pathname}
        />

        <div className="static-container" style={staticStyle}>
          {post.hasSizzle ? (
            <Sizzle
              playing={this.state.playing}
              sizzleButtonStyle={sizzleButtonStyle}
              post={post}
              language={language}
            >
              <StaticHeading
                playing={this.state.playing}
                sizzleButtonStyle={sizzleButtonStyle}
                post={post}
                language={language}
              />
            </Sizzle>
          ) : (
            <StaticHero post={post} />
          )}

          {language == "en" && (
            <>
              {hasReviews && (
                <TestimonialGrid language={language} reviews={allReviews} />
              )}

              {/* {hasReviews && post.featuredReviews.length > 1 && (
                <div className="columns">
                  {post.featuredReviews.map((card) => (
                    <div key={card.youtube} className="column">
                      <TestimonialPreview
                        language={language}
                        youtube={card.youtube}
                        image={card.image}
                        heading={card.heading}
                        subheading={card.subHeading}
                      />
                    </div>
                  ))}
                </div>
              )} */}
            </>
          )}

          <ImageText language={language} post={post} textImage noReverse />

          {/* <FixedFacewallHomepage
            language={language}
            heading={
              language === "es"
                ? "Escuche Acerca de Nuestros Pacientes"
                : "Hear From Our Patients"
            }
            reviews={fixedFacewallReviews}
          /> */}

          <LearnMoreCards language={language} post={post.learnMoreCards} />

          <Exparel post={post.homeExparel} />

          <InTheCommunity
            colorBack={false}
            language={language}
            data={post.inTheCommunity}
          />

          <ReferringProvider
            colorBack={true}
            language={language}
            data={post.referringProvider}
          />

          <ASIRD post={post.homeAsird} />

          <AAAHC post={post.homeAAAHC} language={language} />

          <FirstTime
            colorBack={true}
            language={language}
            post={post}
            noReverse
          />

          <AllRatingsReviews
            colorBack={false}
            animation
            heading={post.homeRatingsReviews.heading}
            language={language}
            buttonHref={post.homeRatingsReviews.buttonOne.href}
            buttonText={post.homeRatingsReviews.buttonOne.buttonText}
          />
        </div>
        {post.toast.hasThisSection && (
          <ProgramToast closeDate={post.toast.closeDate}>
            {post.toast.text}
          </ProgramToast>
        )}
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  pageContext: PropTypes.object,
}

export const pageQuery = graphql`
  query reviewsIndexQuery {
    allReviews: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, reviewLanguage: { ne: "ESP" } }
      limit: 30
    ) {
      nodes {
        ...FacewallData
      }
    }
    allReviewsEs: allSpanishReviewsJson(
      filter: { reviewType: { eq: "Patient" }, reviewLanguage: { eq: "ESP" } }
      limit: 4
    ) {
      nodes {
        title
        reviewerName
        reviewType
        reviewLanguage
        thumbnailPublicId
        mainProcedureDone
        monthYear {
          month
          year
        }
      }
    }
    allUniquePagesJson(filter: { title: { eq: "/" } }) {
      nodes {
        hasSizzle
        intro
        staticHero {
          heading
          subHeading
          sideImage
          logo
          backgroundImage
        }
        toast {
          hasThisSection
          text
          closeDate
        }
        homeSectionOne {
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
          imageId
          text
        }
        imageTexts {
          hasThisSection
          textLeft
          leftColWidth
          leftContentColWidth
          middleColWidth
          rightColWidth
          text
          imageId
          buttons {
            button {
              buttonText
              appearance
              buttonText
              href
              destination
            }
          }
        }
        homeExparel {
          hasThisSection
          imageId
          blurb
          button {
            buttonText
            href
          }
        }
        homeAsird {
          blurb
          hasThisSection
          imageId
          button {
            buttonText
            href
          }
        }
        homeAAAHC {
          blurb
          heading
          hasThisSection
          backgroundImage
          imageId
          button {
            useButton
            buttonText
            href
          }
        }
        learnMoreCards {
          blurb
          hasThisSection
          heading
          cards {
            blurb
            heading
            imageId
            button {
              buttonText
              href
            }
          }
        }
        homeRatingsReviews {
          heading
          text
          buttonOne {
            buttonText
            href
          }
        }
        homeHeadingVideo {
          hasThisSection
          heading
          youtube
          youtubePhotoPublicId
          youtubePhotoPublicIdMobile
        }
        inTheCommunity {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          leftRight
          subheading
          text
        }
        referringProvider {
          youtube
          imageCaption
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          text
        }
        firstTimePatient {
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
          heading
          text
        }
        metaTitle
        sizzleYoutube
        metaDescription
        title
        svgHeading
        svgSubheading
        schemaPage
      }
    }
    spanishIndex: allSpanishUniquePagesJson(filter: { title: { eq: "/es/" } }) {
      nodes {
        hasSizzle
        mobileHeading
        intro
        staticHero {
          heading
          subHeading
          sideImage
          logo
          backgroundImage
        }
        toast {
          hasThisSection
          text
          closeDate
        }
        homeSectionOne {
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
          imageId
          text
        }
        imageTexts {
          hasThisSection
          textLeft
          leftColWidth
          leftContentColWidth
          middleColWidth
          rightColWidth
          text
          imageId
          buttons {
            button {
              buttonText
              appearance
              buttonText
              href
              destination
            }
          }
        }
        homeExparel {
          hasThisSection
          imageId
          blurb
          button {
            buttonText
            href
          }
        }
        homeAsird {
          blurb
          hasThisSection
          imageId
          button {
            buttonText
            href
          }
        }
        homeAAAHC {
          blurb
          heading
          hasThisSection
          backgroundImage
          imageId
          button {
            useButton
            buttonText
            href
          }
        }
        learnMoreCards {
          blurb
          hasThisSection
          heading
          cards {
            blurb
            heading
            imageId
            button {
              buttonText
              href
            }
          }
        }
        homeRatingsReviews {
          heading
          text
          buttonOne {
            buttonText
            href
          }
        }
        homeHeadingVideo {
          hasThisSection
          heading
          youtube
          youtubePhotoPublicId
          youtubePhotoPublicIdMobile
        }
        seoHeading
        inTheCommunity {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          leftRight
          subheading
          text
        }
        referringProvider {
          youtube
          imageCaption
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          text
        }
        firstTimePatient {
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
          heading
          text
        }
        metaTitle
        sizzleYoutube
        metaDescription
        title
        svgHeading
        svgSubheading
        schemaPage
      }
    }
  }
`

export default IndexPage
